import styled from 'styled-components';
import background from '../../assets/diamatic/background_5.png';
import glukometerOval from '../../assets/diamatic/glukometerOval.png';
import diamaticLogotype from '../../assets/diamatic/diamaticLogotype.svg';
import mobileLogo from '../../assets/diamatic/mobileLogo.svg';

export const Wrapper = styled.section`
	background-image: url(${background});
	background-size: contain;
	box-sizing: border-box;
	min-height: 100vh;
	min-height: 100vh;
	width: 100%;
`;

export const InnerWrapper = styled.div`
	height: 100%;
	min-height: 100vh;
	width: 100%;
`;

export const NavBar = styled.nav`
	align-items: center;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	margin: 0 auto 30px;
	max-width: 1498px;
	padding: 36px 16px 0;
	width: 100%;
`;

export const Logo = styled.img.attrs(() => ({
  src: diamaticLogotype
}))`
	display: none;
	margin: 0;
	
	@media (min-width: 760px) {
		display: flex;
	}
`;

export const MobileLogo = styled.img.attrs(() => ({
  src: mobileLogo
}))`
	display: flex;
	margin: 0;
	width: 35px;
	
	@media (min-width: 375px) {
		width: 51px;
	}
	
	@media (min-width: 760px) {
		display: none;
	}
`;

export const LinksWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;

export const StyledLink = styled.span`
	color: #404041;
	cursor: pointer;
	font-family: "Causten Bold";
	font-size: 16px;
	margin: 0 10px;
	text-decoration: none;
	text-transform: uppercase;
	
	@media (min-width: 350px) {
		font-size: 19px;
		margin: 0 15px;
	}
`;

export const Body = styled.div`
	margin: 0 auto;
	max-width: 1420px;
	position: relative;
	width: 100%;
	z-index: 2;
`;

export const BackgroundImage = styled.img.attrs(() => ({
  src: glukometerOval
}))`
  position: absolute;
  bottom: 0;
  height: 777px;
  max-height: 80vh;
  z-index: 0;
  margin-bottom: 0;
  @media (max-width: 1220px) {
    opacity: 0.5;
  }
  @media (max-width: 760px) {
    opacity: 0.5;
    width: 100%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Column = styled.div`
	box-sizing: border-box;
	margin: 19% 0 0 auto;
	max-width: 609px;
	padding: 0 16px;
	width: 100%;
	
	@media (max-width: 900px) {
		margin-top: 25%;
	}
`;

export const Header = styled.h1`
	color: #2e3191;
	font-family: "Causten Extra Bold";
	font-size: 50px;
	line-height: 0.92;
	margin: 0 0 22%;
	max-width: 609px;
	
	@media (min-width: 900px) {
		font-size: 59px;
	}
`;

export const MoreButton = styled.button`
	background-color: #2e3191;
	border-radius: 32px;
	border: none;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	font-family: "Causten Black";
	font-size: 22px;
	font-weight: 900;
	height: 64px;
	line-height: 1.18;
	margin: 20px auto 0 0;
	text-decoration: none;
	text-transform: uppercase;
	width: 246px;
`;
