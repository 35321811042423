import background_4 from '../../assets/diamatic/background_4.png';
import styled from 'styled-components';

export const Wrapper = styled.section`
  background-color: #fff;
  width: 100%;
  background-image: url(${background_4});
  background-size: contain;
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  max-width: 1436px;
  width: 100%;
  padding: 74px 16px 150px 16px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Header = styled.h2`
  font-family: "Causten Extra Bold";
  font-size: 75px;
  line-height: 0.92;
  text-align: center;
  color: #2e3191;
  width: 100%;
  margin: 0;
  
  @media (max-width: 799px) {
    padding-bottom: 200px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform: rotate(90deg);
  min-width: 740px;
  @media (min-width: 800px) {
    transform: none;
    width: 100%;
  }
`;

export const BubblesRow = styled.div<any>`
  display: flex;
  width: 560px;
  justify-content: space-between;
  margin: ${(props) => (props.isReversed ? '0 0 0 auto' : '0 auto 0 0')};
  
  @media (min-width: 800px) {
    width: 76%;
  }

  @media (min-width: 1200px) {
    width: 73%;
  }
`;

export const Line = styled.div`
  width: 100%;
  background-color: #2e3191;
  margin: 0 auto;
  height: 8px;
  width: 500px;
  margin-top: -40px;
  margin-left: 130px;
  opacity: 0.1;

  @media (min-width: 800px) {
    height: 38px;
    width: 75%;
    margin: 0 auto;
    opacity: 1;
  }

  @media (min-width: 1200px) {
    width: 80%;
  }
`;

export const BubbleWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  position: relative;
  bottom: ${(props) => props.direction !== 'column' ? '42px' : '-2px'};

  @media (min-width: 800px) {
    bottom: ${(props) => props.bottom};
  }
`;

export const BasicBubble = styled.div<{direction: string}>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 4px solid #2e3191;
  box-sizing: border-box;
  background-color: #fff;
  margin: ${(props) => props.direction === 'column' ? '0 30px 30px 30px' : '30px 30px 0 30px'};

  @media (min-width: 800px) {
    width: 73px;
    height: 73px;
    border: 13px solid #2e3191;
  }
`;

export const BigBubbleWrapper = styled.div`
  border:  5px solid #2e3191;
  box-sizing: border-box;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  
  @media (min-width: 800px) {
    width: 168px;
    height: 168px;
    border: 17px solid #2e3191;
  }
`;

export const BigBubble = styled.div`
  width: 100%;
  height: 100%;
  border: 2 solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (min-width: 800px) {
    border: 17px solid #dcdcdc;
  }
`;

export const BubbleIcon = styled.img<any>`
  @media (max-width: 799px) {
    transform: ${(props) => (props.direction === 'column-reverse' ? 'rotate(270deg)' : 'rotate(270deg)')};
  }
`;

export const BubbleHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-family: "Causten Extra Bold";
  font-size: 24px;
  line-height: 1.38;
  text-align: center;
  color: #5e5e5e;
`;

export const TextContainer = styled.div<any>`
  @media (max-width: 799px) {
    transform: ${(props) => (props.direction === 'column-reverse' ? 'translate(-38%,-112%) rotate(270deg)' : 'translate(-30%, 80%) rotate(270deg)')};
  }
`

export const BubbleText = styled.p`
  padding: 0;
  font-family: "Causten Regular";
  font-size: 20px;
  max-width: 260px;
  line-height: 1.38;
  text-align: center;
  color: #5e5e5e;

  @media (min-width: 800px) {
    font-size: 24px;
  }
`;

export const Arrow = styled.div<{direction: string}>`
  width: 0px;
  height: 0px;
  border-bottom: 0 solid transparent;
  position: relative;
  top: -5px;
  ${(props) =>
    props.direction === 'column-reverse' &&
    `
    transform: rotate(180deg);
    top: 5px;
  `}
  border-top: 13px solid #2e3191;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;

  @media (min-width: 800px) {
    top: -2px;
    ${(props) =>
      props.direction === 'column-reverse' &&
      `
      transform: rotate(180deg);
      top: 2px;
    `}
    border-top: 23px solid #2e3191;
    border-right: 19px solid transparent;
    border-left: 19px solid transparent;
  }
`;

