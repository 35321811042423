import React from 'react';
import Box from './Box';
import headacheIcon from '../../assets/diamatic/headacheIcon.png';
import stethoscopeIcon from '../../assets/diamatic/stethoscopeIcon.png';
import syringeIcon from '../../assets/diamatic/syringeIcon.png';
import * as S from './Diamatic2.styles';

const data = [
  {
    header: 'Światowa epidemia cukrzycy',
    icon: syringeIcon,
    text: `Cukrzyca typu II to jedna z najczęściej występujących chorób cywilizacyjnych, na którą zapadają coraz młodsi pacjenci. Rocznie z tego powodu umiera w Polsce 21 500 osób. Otyłość, wysokokaloryczna dieta i brak ruchu to główne katalizatory występowania tej odmiany cukrzycy, która stanowi około 85-95% wszystkich przypadków choroby. Odsetek osób dotkniętych tym problemem rośnie lawinowo. W 2040 roku na cukrzycę cierpieć może 11% populacji kraju. Nie jest to jednak problem o zasięgu lokalnym - ONZ uznało gwałtowny wzrost zapadalności na cukrzycę za pierwszą niezakaźną epidemię na świecie. `,
  },
  {
    header: 'Niewidzialne zagrożenie',
    icon: headacheIcon,
    text: `O powodzeniu leczenia decyduje przede wszystkim wczesna diagnostyka, którą zdecydowanie utrudnia fakt, że u ponad połowy chorych nie występują żadne z klinicznych objawów cukrzycy, takie jak zwiększone pragnienie, częste oddawanie moczu, wahania masy ciała czy zmiany skórne. Szacuje się, że około 30% pacjentów w Polsce nie zdaje sobie sprawy z tego, że choruje. Wyzwaniem lekarzy diagnostów staje się wobec tego czynne poszukiwanie cukrzycy i wdrażanie działań profilaktycznych już na wczesnym etapie rozwoju choroby. Przy użyciu tradycyjnych metod diagnostycznych jest to niemal niemożliwe.`,
  },
  {
    header: 'Wczesna diagnostyka ratuje życie',
    icon: stethoscopeIcon,
    text: `Projekt Diamatic umożliwia wczesną detekcję cukrzycy u pacjentów poddawanych rutynowym badaniom EKG. Wykorzystując wiedzę na temat rozpoznanej i opisanej w literaturze medycznej anomalii cukrzycowo - kardiologicznej, Diamatic wskazuje nieprawidłowości w wynikach badań osób, u których nie występują żadne z klasycznych objawów cukrzycy. System umożliwia lekarzom szybką reakcję i skierowanie pacjentów na pogłębioną diagnostykę we wczesnej fazie schorzenia, a zatem wtedy, gdy unormowanie poziomu cukru jest relatywnie proste. `,
  },
];

export default () => (
  <S.Wrapper id="for-whom">
    <S.InnerWrapper>
      {data.map((item: any, index) => (
        <Box key={index} {...item} />
      ))}
    </S.InnerWrapper>
  </S.Wrapper>
);
