import React from 'react';
import * as S from './Diamatic2.styles';

export default ({ header, icon, text }: { header: string; icon?: string; text: string }) => (
  <S.BoxWrapper>
    <S.BoxHeader>{header}</S.BoxHeader>
    <S.BoxBoxWrapper>
      <S.BoxIcon src={icon} />
      <S.Text>{text}</S.Text>
    </S.BoxBoxWrapper>
  </S.BoxWrapper>
);
