import styled from 'styled-components';

export const Header = styled.h2<any>`
	color: #2e3191;
	font-family: "Causten Extra Bold";
	font-size: 75px;
	line-height: 0.92;
	margin: ${(props) => props.sticky ? '10% 0 17%' : '25% 0 17%'};
`;

export const Text = styled.div`
	color: #5e5e5e;
	font-family: "Causten Regular";
	font-size: 26px;
	line-height: 43px;
	margin: 0;
	padding: 0;
`;

export const BoldedBlue = styled.span`
	color: #2e3191;
	font-family: "Causten Black";
	font-size: 30px;
	font-weight: 800;
	white-space: pre-line;
`;
