import * as React from "react"
import Diamatic1 from '../components/Diamatic1/Diamatic1';
import Diamatic2 from '../components/Diamatic2/Diamatic2';
import Diamatic3 from '../components/Diamatic3/Diamatic3';
import Diamatic4 from '../components/Diamatic4/Diamatic4';
import Diamatic5 from '../components/Diamatic5/Diamatic5';
import Diamatic6 from '../components/Diamatic6/Diamatic6';
import Diamatic7 from '../components/Diamatic7/Diamatic7'
import "../components/layout.css";

const IndexPage = () => (
  <>
    <Diamatic1 />
    <Diamatic2 />
    <Diamatic3 />
    <Diamatic4 />
    <Diamatic5 />
    <Diamatic6 />
    <Diamatic7 />
  </>
);

export default IndexPage;
