import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { MdLocalPhone } from 'react-icons/md';
import ContactItem from './ContactItem';
import logotype0 from '../../assets/diamatic/logotype0.png';
import logotype1 from '../../assets/diamatic/logotype1.png';
import logotype2 from '../../assets/diamatic/logotype2.png';
import logotype3 from '../../assets/diamatic/logotype3.png';
import background_6 from '../../assets/diamatic/background_6.png';
import * as S from './Diamatic7.styles';

const contactItems = [
 /* {
    icon: (
      <MdLocalPhone
        style={{ color: '#2e3191', width: '26px', height: '26px', marginRight: '13px' }}
      />
    ),
    header: 'Telefon',
    text: ['(+48) 796 000 300', '(+48) 796 007 77'],
  },*/
  {
    icon: (
      <FaMapMarkerAlt
        style={{ color: '#2e3191', width: '26px', height: '26px', marginRight: '13px' }}
      />
    ),
    header: 'Adres',
    text: ['Diamatic Sp. z o.o.', 'Grunwaldzka 31, 14-200 Iława', 'NIP: 744-182-39-35', 'KRS: 0000828937'],
  },
  {
    icon: (
      <GoMail style={{ color: '#2e3191', width: '26px', height: '26px', marginRight: '13px' }} />
    ),
    header: 'E-mail',
    text: ['jerzy@diamatic.com.pl'],
  },
];

export default () => (
  <S.Wrapper background={background_6} id="contact">
    <S.InnerWrapper>
      <S.Header>Kontakt</S.Header>
      <S.TextNowLight>
        Obecnie projekt realizowany jest przez fundusz inwestycyjny Polish Venture Fund przy
        współfinansowaniu Narodowego Centrum Badań i Rozwoju w ramach programu Bridge Alfa.
      </S.TextNowLight>
      <S.TextBold>
        Szukamy inwestorów gotowych zainwestować działalność spółki w kontekście wejścia na rynek
        oprogramowania medycznego.
      </S.TextBold>
      <S.TextNowLight>
        Kontakt inwestycyjny: www.polishventurefund.pl
      </S.TextNowLight>
      <S.ContactDataWrapper>
        {contactItems.map((item: any, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </S.ContactDataWrapper>
    </S.InnerWrapper>
    <S.Footer>
      <S.FooterBody>
        <S.CopyRight>
          <strong>© 2021 Diamatic</strong>
        </S.CopyRight>
        <S.FoundsWrapper>
		  <S.EuropeFunds src={logotype0} />
          <S.EuropeFunds src={logotype1} />
          <S.EuropeFunds src={logotype2} />
          <S.EuropeFunds src={logotype3} />
        </S.FoundsWrapper>
      </S.FooterBody>
    </S.Footer>
  </S.Wrapper>
);
