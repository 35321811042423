import styled from "styled-components"

export const Wrapper = styled.section<any>`
	background-color: ${props => props.bgColor};
	background-image: url(${props => props.background});
	background-size: contain;
	box-sizing: border-box;
	display: flex;
	min-height: 100vh;
	width: 100%;
`

export const BodyWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: center;
	min-width: 1150px;
	
	@media(max-width: 1150px) {
		min-width: initial;
		padding-right: 0;
	}
`;

export const Body = styled.div<any>`
	${props => props.bodyStyles}
	box-sizing: border-box;
	max-width: 898px;
	padding: 0 16px;
	width: 100%;
`

export const ImageContainer = styled.div`
  min-height: 1000px;
  flex-grow: 1;
  overflow: hidden;
  height: 100vh;
  object-position: right;
  object-fit: cover;
`;

export const SideImage = styled.img`
  min-height: 1000px;
  flex-grow: 1;
  height: 100vh;
  object-fit: cover;
  object-position: left;

  @media(max-width: 1150px) {
    display: none;
  }
`