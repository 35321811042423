import React from 'react';
import DiamaticWrapper from '../DiamaticWrapper/DiamaticWrapper';
import background from '../../assets/diamatic/background_6.png';
import doctorWithSthetoscope from '../../assets/diamatic/stetho.jpg';
import * as S from './Diamatic3.styles';

export default () => (
  <DiamaticWrapper img={doctorWithSthetoscope} background={background} id="how-it-works">
    <>
      <S.Header sticky={true}>Jak działa Diamatic</S.Header>
      <S.Text>
        <p>
          Diamatic oprogramowanie wykorzystujące sieci neuronowe do rozpoznawania w sygnale EKG i
          podstawowych parametrach akcji serca zmian właściwych dla cukrzycy typu II. Oprogramowanie
          bazuje na modułach sztucznej inteligencji, które analizują duże zbiory danych, aby wychwycić
          prawidłowości i podobieństwa w zapisie EKG osób cierpiących na cukrzycę.
        </p>
        <br />
        <p>
          Algorytmy Diamatic dokonują analizy szeregu zmiennych występujących w zapisie EKG i zestawiają go modelami
          utworzonymi na bazie zapisu pacjentów z cukrzycą. System ma zdolność samouczenia się, czyli
          w miarę, jak rozszerza się zbiór danych porównawczych, dokładność sporządzanych przez niego
          modeli rośnie.
        </p>
      </S.Text>
    </>
  </DiamaticWrapper>
);
