import React from 'react';
import DiamaticColumnWrapper from '../DiamaticColumnWrapper/DiamaticColumnWrapper';

const data = [
  {
    title: 'Katastrofalne skutki cukrzycy typu II',
    body: `Nieleczona, cukrzyca typu II może prowadzić między innymi zawału serca, udaru mózgu,
ślepoty, impotencji czy amputacji kończyn. Nie tylko drastycznie obniża jakość życia
pacjentów, ale stanowi dla niego bezpośrednie zagrożenie. Szacuje się, że bezpośrednie
roczne koszty leczenia cukrzycy na świecie wynoszą ponad 827 mld dolarów. W Europie jest
to już około 9% wydatków na zdrowie. Dzięki wykorzystaniu systemu Diamatic możliwe jest
nie tylko zredukowanie nakładów ponoszonych na leczenie cukrzycy ale i podjęcie leczenia
jeszcze na bardzo wczesnym rozwoju choroby, kiedy o powodzeniu leczenia mogą w dużej
mierze przesądzić działania profilaktyczne, takie jak zmiana diety czy regularny ruch.
Osoby zdiagnozowane na tym etapie żyją średnio 10 lat dłużej niż pacjenci, u których
terapię rozpoczęto przy pełnoobjawowej cukrzycy.`,
  },
  {
    title: `Wsparcie dla lekarzy i\u00A0personelu medycznego`,
    body: `Wyzwaniem dla wielu współczesnych lekarzy rodzinnych jest brak czasu na pogłębioną
diagnostykę pacjentów. O skierowaniu chorego na badania krwi decyduje w dużej mierze
intuicja i doświadczenie lekarza. Samouczące się algorytmy Diamatic są miarodajnym
wsparciem dla lekarzy pierwszego kontaktu i alarmują ich o przesłankach do wykonania
szczegółowych badań nawet wówczas, gdy pacjent nie uskarża się na złe samopoczucie a
cukrzyca przebiega u niego bezobjawowo. Badanie systemem Diamatic może wykonać także
odpowiednio przeszkolony personel pielęgniarski i medyczny. System jest kompatybilny z
różnymi aparatami EKG toteż możliwe jest jego szybkie wdrożenie w placówkach medycznych,
bez konieczności zakupu dodatkowego sprzętu.`,
  },
];

export default () => <DiamaticColumnWrapper columns={data} />;
