import React from 'react';
import DiamaticWrapper from '../DiamaticWrapper/DiamaticWrapper';
import doctorWithSthetoscope from '../../assets/diamatic/stetho.jpg';
import background from '../../assets/diamatic/background_6.png';
import * as S from '../Diamatic3/Diamatic3.styles';

export default () => (
  <DiamaticWrapper img={doctorWithSthetoscope} background={background}>
    <>
      <S.Header>Cukrzyca w liczbach</S.Header>
      <S.Text>
        <S.BoldedBlue>279 000</S.BoldedBlue> osób leczono w polskich szpitalach z powodu cukrzycy w
        2018
        <br />
        <S.BoldedBlue>2,9 mln</S.BoldedBlue> dorosłych Polaków choruje na cukrzycę
        <br />
        <S.BoldedBlue>30%</S.BoldedBlue> cukrzyków nie wie o swojej chorobie
        <br />
        chorzy na cukrzycę żyją średnio <S.BoldedBlue>15 lat</S.BoldedBlue> krócej
        <br />
        <S.BoldedBlue>1 na 4</S.BoldedBlue> Polaków powyżej 60 życia cierpi na cukrzycę
      </S.Text>
    </>
  </DiamaticWrapper>
);
