import React from 'react';
import * as S from './Diamatic4.styles';

export default ({
  direction,
  bottom,
  title,
  text,
  icon,
}: {
  direction: 'column' | 'column-reverse';
  bottom: string;
  title: string;
  text: string;
  icon: string;
}) => (
  <S.BubbleWrapper direction={direction} bottom={bottom}>
    <S.TextContainer direction={direction}>
      <S.BubbleHeader>{title}</S.BubbleHeader>
      <S.BubbleText>{text}</S.BubbleText>
    </S.TextContainer>
    <S.BigBubbleWrapper>
      <S.BigBubble>
        <S.BubbleIcon src={icon} />
      </S.BigBubble>
    </S.BigBubbleWrapper>
    <S.Arrow direction={direction} />
    <S.BasicBubble direction={direction} />
  </S.BubbleWrapper>
);
