import styled from 'styled-components';

export const Wrapper = styled.section <{ background: string, id: string }>`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  background-image: url(${(props) => props.background});
  background-size: contain;
`;

export const InnerWrapper = styled.div`
  max-width: 1360px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 70px;
`;

export const Header = styled.h2`
  margin: 0 0 41px;
  width: 100%;
  font-family: "Causten Extra Bold";
  font-size: 75px;
  line-height: 0.92;
  text-align: center;
  color: #2e3191;
`;

export const Text = styled.p`
  width: 100%;
  font-family: Now;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  margin: 0 0 73px;
`;

export const TextNowLight = styled.p`
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  font-family: 'Now Light';
`

export const TextBold = styled.p`
  width: 100%;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  font-family: 'Now Bold';
`

export const ContactDataWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 37px;
  flex-wrap: wrap;
  margin-top: 140px;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 178px;
  background-color: #fff;
  margin: auto 0 0;
`;

export const FooterBody = styled.div`
  box-sizing: border-box;
  max-width: 1420px;
  width: 100%;
  padding: 50px 16px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 930px) {
    flex-wrap: wrap;
  }
`;

export const FoundsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 2;
`;

export const CopyRight = styled.p`
  font-family: "Causten Regular";
  font-size: 19px;
  text-align: center;
  color: #707070;
  flex-grow: 1;
`;

export const ItemWrapper = styled.div`
  display: flex;
  min-width: 182px;
  margin-bottom: 18px;
  min-width: 215px;
`;

export const ItemIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 13px;
`;

export const ItemBody = styled.div``;

export const ItemHeader = styled.h6`
  font-family: "Causten Bold";
  font-size: 20px;
  font-weight: bold;
  color: #224750;
  margin: 0;
`;

export const ItemText = styled.p`
  margin: 0;
  font-family: "Causten Regular";
  font-size: 18px;
  color: #707070;
`;

export const EuropeFunds = styled.img`
  object-fit: contain;
`;

// dusk-blue
// color: #2e3191;
