import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import * as S from './Diamatic1.styles';

export default () => (
  <S.Wrapper>
    <S.InnerWrapper>
      <S.NavBar>
        <S.Logo />
        <S.MobileLogo />
        <S.LinksWrapper>
          <S.StyledLink onClick={() => scrollTo('#for-whom')}>Dla kogo?</S.StyledLink>
          <S.StyledLink onClick={() => scrollTo('#how-it-works')}>Jak to działa?</S.StyledLink>
          <S.StyledLink onClick={() => scrollTo('#contact')}>Kontakt</S.StyledLink>
        </S.LinksWrapper>
      </S.NavBar>
      <S.Body>
        <S.Column>
          <S.Header>Sztuczna inteligencja w&nbsp;profilaktyce cukrzycy typu II</S.Header>
          {/* <S.MoreButton>Więcej</S.MoreButton> */}
        </S.Column>
      </S.Body>
    </S.InnerWrapper>
    <S.BackgroundImage />
  </S.Wrapper>
);
