import React from 'react';
import Bubble from './Bubble';
import binoImage from '../../assets/diamatic/bino.png';
import dollarImage from '../../assets/diamatic/dollar.png';
import painImage from '../../assets/diamatic/pain.png';
import personImage from '../../assets/diamatic/person.png';

import * as S from './Diamatic4.styles';

export default () => (
  <S.Wrapper >
    <S.InnerWrapper>
      <S.Header>Diamatic to:</S.Header>
      <S.BodyWrapper>
        <S.BubblesRow>
          <Bubble
            title="Wczesne rozpoznanie"
            text="cukrzycy u pacjentów bezobjawowych"
            icon={painImage}
            bottom="-85px"
            direction="column"
          />
          <Bubble
            title="Niski koszt"
            text="i wysoka efektywność badania"
            icon={dollarImage}
            bottom="-85px"
            direction="column"
          />
        </S.BubblesRow>
        <S.Line />
        <S.BubblesRow isReversed>
          <Bubble
            title="Nieinwazyjna"
            text="diagnostyka cukrzycy"
            icon={binoImage}
            bottom="85px"
            direction="column-reverse"
          />
          <Bubble
            title="Wsparcie dla lekarzy"
            text="i personelu medycznego"
            icon={personImage}
            bottom="85px"
            direction="column-reverse"
          />
        </S.BubblesRow>
      </S.BodyWrapper>
    </S.InnerWrapper>
  </S.Wrapper>
);
