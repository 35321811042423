import styled from 'styled-components';

export const Wrapper = styled.section`
	background-color: #fff;
	width: 100%;
`;

export const InnerWrapper = styled.div`
	box-sizing: border-box;
	display: flex;
	height: 100%;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 1436px;
	min-height: 100vh;
	padding: 83px 0 80px;
	width: 100%;
	
	@media (max-width: 900px) {
		align-items: center;
		flex-direction: column;
	}
`;

export const BoxWrapper = styled.article`
	display: flex;
	flex-direction: column;
	margin: 0 16px;
	max-width: 427px;
	
	@media (max-width: 900px) {
		margin: 0 16px 77px;
		max-width: 577px;
	}
`;

export const BoxHeader = styled.h2`
	box-sizing: border-box;
	color: #2e3191;
	font-family: "Causten Extra Bold";
	font-size: 38px;
	line-height: 0.9;
	margin: 0 0 37px;
	padding: 0 23px;
	text-align: center;
	width: 100%;
	
	@media (max-width: 1365px) {
		height: 108px;
		min-height: 108px;
	}
	
	@media (max-width: 900px) {
		height: auto;
		margin: 0 0 27px;
	}
`;

export const BoxBoxWrapper = styled.div`
	border-radius: 17px;
	box-shadow: rgb(100 100 111 / 50%) 3px 4px 29px 0px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 37px 21px;
	width: 100%;
`;

export const Text = styled.p`
	color: #5e5e5e;
	font-family: "Causten Regular";
	font-size: 22px;
	letter-spacing: -0.33px;
	line-height: 1.59;
	margin: 0;
	padding: 0;
	text-align: center;
`;

export const BoxIcon = styled.img`
	height: 87px;
	margin: 0 auto 27px;
	width: 87px;
`;
